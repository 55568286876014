import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { PrintQueueMaintenanceProvider } from "presentation/store/PrintQueue/PrintQueueMaintenanceProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { PrintQueueMasterView } from "presentation/view/section/PrintQueue/PrintQueueMasterView";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const PrintQueueMaintenanceCont = () => {
    return <ANAInfoWrapper permission={Permission.PRINT_QUEUE_MAINTENANCE}>
        <MessageBarWrapper>
            <PrintQueueMaintenanceProvider>
                <GridStyles/>
                <PrintQueueMasterView/>
            </PrintQueueMaintenanceProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default PrintQueueMaintenanceCont;