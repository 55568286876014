import { PrintQueueMaintenanceConstant } from "./PrintQueueMaintenanceConstant";

const SCREEN_CONSTANT = PrintQueueMaintenanceConstant;
export const INITIAL_PRINT_QUEUE_COL_DEF: any[] = [
    {
        headerName: SCREEN_CONSTANT.PRINT_QUEUE_NAME,
        field: "printQueueName",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 230,
        pinned: "left",
        /*checkboxSelection: true,
        headerCheckboxSelection: true,*/
    },
    {
        headerName: SCREEN_CONSTANT.PRINT_QUEUE_DESC,
        field: "printQueueDesc",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 300,
    },
    {
        headerName: SCREEN_CONSTANT.DEFAULT_INV_CN,
        field: "defaultInvCn",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.DEFAULT_SUPPORTING_DOCUMENT,
        field: "defaultSupportingDocument",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 280,
    },
    {
        headerName: SCREEN_CONSTANT.MONTH_PATH_ACTIVE,
        field: "monthPathActive",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.SEQUENTIAL_PRINTING_ENABLED,
        field: "enabledSeqLabel",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 250,
    },
    {
        headerName: SCREEN_CONSTANT.SEQUENTIAL_PRINT_LABEL,
        field: "sequentialPrintingLabel",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 230,
    },
    {
        headerName: SCREEN_CONSTANT.PRINT_QUEUE_TRAY,
        field: "printQueueTray",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
    },
    {
        headerName: SCREEN_CONSTANT.ACTIVE_IND,
        field: "activeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
    },

]
