
import { usePrintQueueMaintenanceTracked } from "presentation/store/PrintQueue/PrintQueueMaintenanceProvider";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import PrintQueueMaintenance from "./PrintQueueMaintenance";
import { PrintQueueMaintenanceFormPanel } from "./PrintQueueMaintenanceFormPanel";

export const PrintQueueMasterView: React.FC = () => {
    const [printQueueState] = usePrintQueueMaintenanceTracked();
    const { isSliderOpen } = printQueueState.masterState;

    return <>
        <div className="main-comp-wrapper im-hide-side-form-draggable">
            <PrintQueueMaintenance />
            {
                ((isSliderOpen) &&
                    <SliderPanel
                        isOpen={false}
                        leftSectionWidth={"100%"}
                        rightSectionWidth={"0%"}
                        leftChildren={<PrintQueueMaintenanceFormPanel />}
                    />
                )
            }
        </div>
    </>
}