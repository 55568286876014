export interface PrintQueueEntity {
    id: number ,
    printQueueName: string,
    printQueueDesc: string|null,
    enabledSeqLabel: "Y"|"N",
    sequentialPrintingLabel: string|null,
    monthPathActive: "Y"|"N",
    defaultInvCn: "Y"|"N",
    defaultSupportingDocument: "Y"|"N",
    printQueueTray: string|null,
    activeInd: "Y"|"N",
    [key:string]: string | number | null | undefined

}

export const EMPTY_PRINT_QUEUE_ENTITY:PrintQueueEntity = {
    id: 0,
    printQueueName: "",
    printQueueDesc: null,
    sequentialPrintingLabel: null,
    monthPathActive: "N",
    defaultInvCn: "N",
    defaultSupportingDocument: "N",
    printQueueTray: "",
    activeInd: "Y",
    enabledSeqLabel: "N"
}
