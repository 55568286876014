import { EMPTY_MASTER_PRINT_QUEUEANY_MODEL } from "presentation/model/PrintQueue/PrintQueueMaintenanceModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: PrintQueueMaintenanceProvider,
    useTracked: usePrintQueueMaintenanceTracked
} = createContainer(() => useState(EMPTY_MASTER_PRINT_QUEUEANY_MODEL), {concurrentMode: true});
export { PrintQueueMaintenanceProvider, usePrintQueueMaintenanceTracked };

