import * as yup from 'yup';


export const commonPrintQueueMaintenanceValidation: { [x: string]: any; } = {
  printQueueName: yup.string().required("Missing"),
};

export const createPrintQueueMaintenanceValidation: { [x: string]: any; } = {
  ...commonPrintQueueMaintenanceValidation,
};

export const createPrintQueueMaintenanceValidationSchema = yup.object().shape(createPrintQueueMaintenanceValidation);

