export const printQueueMaintenanceRequiredFieldList: string[] = [
    'printQueue',
];

export const PrintQueueMaintenanceConstant = {
    CATEGORY: "Support",
    TITLE: "Print Queue",
    PRINT_QUEUE: "Print Queue",
    PRINT_QUEUE_NAME: "Print Queue Name",
    PRINT_QUEUE_DESC: "Print Queue Desc.",
    DEFAULT_INV_CN: "Default (INV & CN)",
    DEFAULT_SUPPORTING_DOCUMENT: "Default (Supporting Document)",
    MONTH_PATH_ACTIVE: "Month Path Active",
    SEQUENTIAL_PRINTING_ENABLED: "Sequential Printing Enabled",
    SEQUENTIAL_PRINT_LABEL: "Sequential Print Label.",
    PRINT_QUEUE_TRAY: "Print Queue Tray.",
    ACTIVE_IND: "Active Ind.",

}
