
import { EMPTY_PRINT_QUEUE_ENTITY, PrintQueueEntity } from "domain/entity/Common/PrintQueueEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface PrintQueueDropdownOptions {
    seqLabelDropdownOptions: DropdownProps[],
}
export interface MasterChangeState extends BaseViewChangeSate {
    editingEntity: PrintQueueEntity,
}

export interface PrintQueueMaintenanceModel {
    isLoading: boolean,
    tableData: PrintQueueEntity[],
    currentSelectedRow: PrintQueueEntity,
    selectedRows: PrintQueueEntity[],
    masterState: MasterChangeState,
    isBackMaster: boolean,
}

export const EMPTY_MASTER_PRINT_QUEUEANY_MODEL: PrintQueueMaintenanceModel = {
    isLoading: false,
    tableData: [],
    currentSelectedRow: { ...EMPTY_PRINT_QUEUE_ENTITY },
    selectedRows: [],
    masterState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        editingEntity: EMPTY_PRINT_QUEUE_ENTITY,
    },
    isBackMaster: false,
}