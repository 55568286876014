
import { PrintQueueRepoImpl } from "domain/repository/Common/PrintQueueRepoImpl";
import { usePrintQueueMaintenanceTracked } from "presentation/store/PrintQueue/PrintQueueMaintenanceProvider";
import { PrintQueueMaintenanceVM } from "presentation/viewModel/PrintQueue/PrintQueueMaintenanceVM";
import { useMemo } from "react";

export const usePrintQueueMaintenanceVM = () => {
    const [, setPrintQueueMaintenanceState] = usePrintQueueMaintenanceTracked();
    const printQueueMainVM = useMemo(() =>
        PrintQueueMaintenanceVM({
            dispatch: [setPrintQueueMaintenanceState],
            repo: PrintQueueRepoImpl(),
        }), [setPrintQueueMaintenanceState])

    return printQueueMainVM
}

