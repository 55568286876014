import { DropdownProps } from "presentation/model/DropdownProps";

export const comboBoxSequentialPrintOption: DropdownProps[] = [({
    dropdownLabel: "SQP1",
    tagLabel: "SQP1",
    value: "SQP1",
}),({
    dropdownLabel: "SQP2",
    tagLabel: "SQP2",
    value: "SQP2",
}),({
    dropdownLabel: "SQP3",
    tagLabel: "SQP3",
    value: "SQP3",
}),({
    dropdownLabel: "SQP4",
    tagLabel: "SQP4",
    value: "SQP4",
}),({
    dropdownLabel: "SQP5",
    tagLabel: "SQP5",
    value: "SQP5",
}),({
    dropdownLabel: "SQP6",
    tagLabel: "SQP6",
    value: "SQP6",
}),({
    dropdownLabel: "SQP7",
    tagLabel: "SQP7",
    value: "SQP7",
}),({
    dropdownLabel: "SQP8",
    tagLabel: "SQP8",
    value: "SQP8",
}),({
    dropdownLabel: "SQP9",
    tagLabel: "SQP9",
    value: "SQP9",
}),({
    dropdownLabel: "SQP10",
    tagLabel: "SQP10",
    value: "SQP10",
})];